:root {
  --primary-color: #08F1EB;
  --secondary-color: #ffffff;
  --font-family: "Alegreya Sans", sans-serif;
}
* {
  font-family: var(--font-family) !important;
}

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family) !important;
}

.full-page-background {
  height: 100%;
  /* background-image: url('../public/background.jpg'); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  /* filter: brightness(0.7); */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--primary-color);
  position: relative;
}

.auth-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/background.jpg");
  /* Path to your background image */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: rgba(0, 0, 0, 0.4); */
  opacity: 0.9;
  /* Adjust the opacity to make it duller */
}

.auth-box {
  width: 400px;
  padding: 20px;
  background: #ffffff;
  /* box-shadow: 0 2px 8px rgba(72, 140, 122, 0.8); */
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(255, 255, 255, 0.12) 0px -12px 30px,
    rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* Example: semi-transparent white background for the content */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0.2, 0.2, 0.2, 0.2); */
  /* Example: light shadow */
  z-index: 1;
}

.auth-toggle {
  margin-bottom: 20px;
}

/* For Signup */

.auth-container-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--primary-color);
  position: relative;
}

.auth-container-signup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/background.jpg");
  /* Path to your background image */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: rgba(0, 0, 0, 0.4); */
  opacity: 0.9;
  /* Adjust the opacity to make it duller */
}

.auth-box-signup {
  width: 700px;
  min-height: 600px;
  padding: 20px;
  background: #ffffff;
  /* box-shadow: 0 2px 8px rgba(72, 140, 122, 0.8); */
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(255, 255, 255, 0.12) 0px -12px 30px,
    rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* Example: semi-transparent white background for the content */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0.2, 0.2, 0.2, 0.2); */
  /* Example: light shadow */
  z-index: 1;
}

.login-form .welcome {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 0;
}
.login-form .login-text {
  color: rgb(168, 167, 167) !important;
}
.login-form label {
  color: black !important;
}
.login-form input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  border-color: white !important;
  background-color: transparent !important;
  color: black !important;
}
.login-form .custom-password-input:focus {
  border-bottom-color: black !important;
  box-shadow: none;
}

.glowingbtn {
  background-color: #08F1EB !important;
  animation: glowing-border 2s linear infinite !important;
  border: 2px solid #08F1EB !important;
  border-radius: 8px;
  box-shadow: 0 0 5px #08F1EB !important;
  position: relative;
  padding: 20px;
  color: black !important;
}
.glowingbtn-white{
  background-color: #ffffff !important;
  animation: glowing-border 2s linear infinite !important;
  border: 2px solid #08F1EB !important;
  border-radius: 8px;
  box-shadow: 0 0 5px #08F1EB !important;
  position: relative;
  padding: 20px;
  color: black !important;
}
.glowingbtncancel {
  background-color: white !important;
  animation: glowing-border 2s linear infinite !important;
  border: 2px solid #08F1EB !important;
  border-radius: 8px;
  box-shadow: 0 0 5px #08F1EB !important;
  position: relative;
  padding: 20px;
  color: black !important;
}
.searchglowingbtn {
  background-color: #08F1EB !important;
  animation: glowing-border 2s linear infinite !important;
  border: 1px solid #08F1EB !important;
  border-radius: 8px;
  box-shadow: 0 0 5px #08F1EB !important;
  position: relative;
  padding: 15px;
  color: black !important;
}
.login-form .ant-steps-item-title {
  color: black !important;
}
.login-form .ant-progress-outer {
  background-color: white;
  border-radius: 30px;
}

.login-form .ant-input-outlined {
  background-color: white !important;
  border: none;
  border-bottom: 2px solid #000 !important; /* Adjust the color as needed */
  color: black !important; /* For visibility */
  border-radius: 0 !important;
}
.ant-input{
  color: black !important;
}
.login-form .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 2px solid #000 !important;
  border-radius: 0 !important;
  color: white !important;
}
.ant-select-selection-item::after {
  background-color: white !important;
}
.user-type-card:hover {
  box-shadow: rgba(106, 242, 236, 0.35) 0px 7px 29px 0px;
}
.user-type-card {
  border-color: #08F1EB;
}

.error-text {
  font-size: 50px;
  font-weight: 800;
}

.ant-switch.ant-switch-checked {
  background-color: #b7b5b5 !important;
  color: #08F1EB !important;
}
.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #08F1EB !important ;
}

.res-flex {
  display: flex;
  gap: 5px;
}
@media (max-width: 768px) {
  .res-flex {
    display: block;
    gap: 5px;
  }
}
@media (max-width: 1100px) {
  .menu-toggle {
    display: block !important;
  }
}
.menu-toggle {
  display: none;
}

.approve {
  background-color: white !important;
  color: #5baf3f !important;
  border: 1px solid #5baf3f;
}
.reject {
  background-color: white !important;
  color: #5baf3f !important;
  border: 1px solid #5baf3f;
}

.ant-drawer-left > .ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.searchbtn button {
  background-color: white !important;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.selected-flag:hover {
  background-color: transparent !important;
  border: none !important;
}

.phone-input-container .flag-dropdown { 
  display: none;
}
.react-tel-input .form-control{
  padding-left: 10px !important;
  border-bottom: 2px solid black !important;
}

.cardbg{
  background-image: url("../src/assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid #08F1EB;
}

.ant-form-item-label span{
  color: black !important;
}
.ant-form-item-control-input-content input{
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0 !important;
}